.tabTitle {
	@apply tw-text-lg md:tw-text-xl;
}

.headline {
	@apply tw-heading-4 md:tw-heading-2 md:tw-pb-5 tw-text-center;
}

.root {
	@apply tw-max-w-[1600px] tw-m-auto;
}
