.heroContainer {
    @apply tw-overflow-hidden tw-relative;
}

.heroBackgroundColor {
    --backgroundColor: '#ffffff';
    background-color: var(--backgroundColor);
    @apply tw-flex tw-justify-center tw-px-2;

    .heroHeadlineButtonContainer {
        @apply tw-py-[65px];
    }
    .heroHeadline {
        @apply tw-text-[80px];
    }

    //640 - 1200
    @media only screen and (min-width: 640px) and (max-width: 1199px) {
        @apply tw-px-0;

        .heroHeadlineButtonContainer {
            @apply tw-pt-[138px] tw-pb-[98px];
        }
        .heroHeadline {
            @apply tw-text-[145px];
        }
    }
    // 1200 - 1600
    @media only screen and (min-width: 1200px) and (max-width: 1607px) {
        .heroHeadlineButtonContainer {
            @apply tw-py-[10.8%];
        }
        .heroHeadline {
            @apply tw-text-[200px];
        }
    }
    //1600+
    @screen xl {
        @apply tw-h-[825px] tw-items-center;
        .heroHeadline {
            @apply tw-text-[200px];
        }
    }
}

.heroHeadlineButtonContainer {
    --nudgeHorizontal: 0;
    --nudgeVertical: 0;

    &.horizontalCenter {
        margin-left: var(--nudgeHorizontal) !important;
    }
    &.horizontalLeft {
       left: var(--nudgeHorizontal) !important;
    }
    &.horizontalRight {
       right: var(--nudgeHorizontal) !important;
    }
    &.verticalBottom {
       bottom: var(--nudgeVertical) !important;
    }
    &.verticalCenter {
        margin-top: var(--nudgeVertical) !important;
    }
    &.verticalTop {
       top: var(--nudgeVertical) !important;
    }
}

.heroHeadline {
    @apply xs:tw-text-[42px] sm:tw-text-5xl md:tw-text-5xl lg:tw-text-6xl tw-font-light tw-leading-tight tw-mb-3;
}

.imgContainer {
    // max 639
    @screen sm {
        @apply tw-h-[330px];
    }

    @media only screen and (min-width: 640px) and (max-width: 1199px) {
        @apply tw-h-[515px] tw-w-full;
    }

    // // min 1920
    @screen xxl {
        @apply tw-h-[825px] tw-w-full;
    }
}

.heroImg {
    --imgHorizontalAlignment: 'center';
    --imgNudgeHorizontalXS: 0;


    @apply tw-object-cover tw-w-full tw-h-full;
    position: relative !important;
    object-position: var(--imgHorizontalAlignment);

    // // max 450
    @screen xs {
        object-position: var(--imgNudgeHorizontalXS) 0;
    }

    // min 1920
    @screen xxl {
        height: unset !important;
    }
}


/* Hero with Video */
.videoContainer {
    @apply tw-w-full tw-z-0;
}