.featuredCategoryContainer {
    @apply tw-flex tw-flex-row tw-space-y-0 tw-space-x-4;
    @screen xs {
        @apply tw-flex-col tw-space-x-0 tw-space-y-5;
    }
}

.featuredCategoryImgContainer {
    @apply tw-mb-2;
    @screen xs {
        @apply tw-overflow-hidden tw-relative tw-h-[260px] tw-w-full tw-object-cover;
    }
}

.featuredCategoryImgContainer img {
    @screen xs {
        @apply tw-object-cover tw-w-full tw-h-full;
        object-position: 0 var(--imgNudgeVerticalXS);
    }
}

.featuredCategoryLink {
    @apply tw-no-underline tw-text-gray tw-flex tw-flex-col tw-w-full tw-h-full;

    &:focus {
        outline: 1px dotted #757575;
        outline-offset: 2px;
        box-shadow: 0 0 1px #ccc;
    }

    &:hover .featuredCategoryTitle,
    &:focus .featuredCategoryTitle {
        @apply tw-underline;
    }
}