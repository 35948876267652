.root {
	@apply tw-hidden md:tw-block tw-mt-9;
}

.rootStaticSlider {
	@apply md:tw-hidden;
	
	li {
		@apply tw-mr-[5px] tw-min-w-[280px];
	}
}
