.clearanceTitle {
    @apply tw-mb-5 tw-relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        width: 100vw;
        height: 1px;
        background: rgba(255, 255, 255, 1.0);
        z-index: 1;
    }
    @screen md {
        @apply tw-mb-10;
    }
}