.slider-dots {
	@apply tw-flex tw-mb-0;

	:global .flicking-camera {
		@apply tw-items-center;
	}
}

.slider-dot-container {
	@apply tw-m-[6px];
}

.slider-dot {
	@apply tw-border tw-border-transparent tw-flex tw-h-[18px] tw-items-center tw-justify-center tw-p-[2px] tw-rounded-full tw-w-[18px];

	&:before {
		@apply tw-bg-gray-400 tw-content-[''] tw-ease-in-out tw-h-[12px] tw-shrink-0 tw-rounded-full tw-transition tw-transition-all tw-w-[12px];
	}

	&:before {
		&:focus, &:hover {
			@apply tw-bg-gray-300;
		}
	}
}

.slider-dot-current {
	@apply tw-border-gray-300;

	&:before {
		@apply tw-bg-gray-300;
	}
}

.slider-dot-small {
	&:before {
		@apply tw-h-[6px] tw-w-[6px];
	}
}
